<template>
    <ul class="p-3">
      <where-list-item v-for="(item, index) in data" :key="index" :item="item" :name="name" @input="changeValue" :checked="item.name === value" />
    </ul>
</template>

<script>
import WhereListItem from '@/components/WhereListItem.vue'
export default {
  name: 'where-list',
  components: {
    WhereListItem
  },
  props: {
    data: {
      type: [Array],
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    changeValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
