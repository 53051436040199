<template>
  <li>
    <router-link :to="{ name: item.path }" class="block py-3 2xl:py-4 pl-8 pr-4 hover:bg-primary-50 hover:text-primary font-medium border-l-8 border-solid border-transparent">
      {{ item.name }}
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'AppNavItems',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply border-primary text-secondary;
}
</style>
