<template>
    <li
      @click="toggleOrder"
      :class="{'font-bold':isOrderedBy}"
      class="order-item px-2 py-2 hover:bg-primary-50 rounded-lg flex items-center justify-between cursor-pointer">
      <span>{{ item.name }}</span>
      <span
        :class="{'font-semibold':isOrderedBy}"
        class="order text-sm" :inner-html.prop="order | setOrder"></span>
    </li>
</template>

<script>
export default {
  name: 'order-list-item',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  filters: {
    setOrder: function (value) {
      let order = ''
      switch (value) {
        case 1:
          order = 'Asc. <b>&utrif;</b>'
          break
        case -1:
          order = 'Desc. <b>&dtrif;</b>'
          break
        default:
          order = 'Na.'
          break
      }
      return order
    }
  },
  computed: {
    order: function () {
      return this.item.order
    },
    isOrderedBy: function () {
      return this.item.order !== 0
    },
    setOrder: function () {
      return this.item.order !== null ? this.item.order : 'Na'
    },
    activeOrder: function () {
      return this.$store.getters['filters/activeOrder']
    }
  },
  methods: {
    toggleOrder: function () {
      const prop = this.item.prop
      const activeOrder = this.activeOrder

      let value = -1
      if (activeOrder && activeOrder.prop === prop) {
        switch (activeOrder.order) {
          case -1:
            value = 1
            break
          case 1:
            value = 0
            break

          default:
            value = -1
        }
      }

      this.$store.commit('filters/setOrder', { prop, value })
    }
  }
}
</script>

<style lang="scss" scoped>
  .order-item {
    &:hover{
      @apply text-primary;
    }
  }
</style>
