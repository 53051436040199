<template>
  <div class="shadow bg-white border border-solid border-gray-100 rounded-lg">
    <header v-if="logo" class="px-4 border-b border-solid border-gray-100 py-2">
      <div class="max-w-logo mx-auto">
        <base-logo :url="logo" />
      </div>
    </header>
    <base-collapsible id="config" customHeader="p-3" customContent="p-3 pt-0" customToggleIcon="config">
      <template slot="headerRight">
        <div class="w-10 h-10 bg-primary-50 rounded-full flex items-center justify-center flex-shrink-0">
          <span class="font-bold">{{ user.userName | simple }}</span>
        </div>
        <div class="w-full overflow-hidden ml-2 text-sm">
          <span class="truncate block font-semibold">{{ user.userName }}</span>
          <span class="text-primary hover:text-primary-900 cursor-pointer" @click="doLogout">{{ $t('app.logout') }}</span>
        </div>
      </template>
      <template slot="content">
        <ul>
          <li class="pb-2">
            <base-select v-model="locale" :data="languages" field-key="code" field-value="code" field-text="name" size="xs" custom="w-full" />
          </li>
        </ul>
      </template>
    </base-collapsible>
  </div>
</template>

<script>
import { LANGUAGES } from '@/config/general'

export default {
  name: 'AppNavUser',
  data: function () {
    return {
      languages: LANGUAGES
    }
  },
  computed: {
    user: function () {
      return this.$store.getters['auth/user']
    },
    locale: {
      get: function () {
        return this.$store.getters['auth/locale']
      },
      set (value) {
        this.$store.commit('auth/setLocale', value)
        this.$store.commit('filters/setLocaleOrder')
        this.$store.dispatch('auth/updateUser')
      }
    },
    logo: function () {
      return this.$store.getters['auth/logo']
    }
  },
  methods: {
    doLogout: async function () {
      try {
        this.$store.commit('app/setLoading', true)
        await this.$store.dispatch('auth/logout')
      } catch (error) {
        this.doFeedback()
      } finally {
        this.$store.commit('app/setLoading', false)
      }
    }
  },
  watch: {
    locale: function () {
      this.$router.go()
    }
  },
  filters: {
    simple: function (value) {
      if (value.length >= 2) {
        return value.substring(0, 2).toUpperCase()
      }

      return value
    }
  }
}
</script>

<style lang="scss" scoped>
input[type="color"] {
  border: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
</style>
