<template>
    <ul class="p-1">
      <order-list-item v-for="(item, index) in data" :key="index" :item="item" />
    </ul>
</template>

<script>
import OrderListItem from '@/components/OrderListItem.vue'
export default {
  name: 'order-list',
  components: {
    OrderListItem
  },
  props: {
    data: {
      type: [Array],
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
