<template>
  <aside class="w-full w-aside hidden 2xl:flex flex-col bg-white overflow-hidden">
    <app-nav />
    <section class="user flex-shrink-0 p-2">
      <app-nav-user />
    </section>
  </aside>
</template>

<script>
import AppNav from '@/components/AppNav.vue'
import AppNavUser from '@/components/AppNavUser.vue'

export default {
  name: 'AppAside',
  components: {
    AppNav,
    AppNavUser
  }
}
</script>

<style lang="scss" scoped>
.w-aside {
  min-width:16.25rem;
  width:16.25rem;
}
</style>
