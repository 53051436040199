<template>
  <div class="w-full h-full overflow-x-auto">
    <header class="py-3 text-center hidden">
      <div class="max-w-logo mx-auto">
        <base-logo
          filename="logo-karcher.png"
          custom=""
        />
      </div>
    </header>
    <div class="flex flex-col space-y-2 px-4 pb-4 pt-4 border-b border-solid border-secondary-50">
      <!--<base-select v-model="groupId" :data="groups" field-key="idr" field-value="id" field-text="name" size="xs" custom="w-full" />-->
      <base-select v-model="countryCode" :data="countries" field-key="code" field-value="code" field-text="label" size="xs" custom="w-full" />

    </div>
    <nav>
      <ul>
        <app-nav-items
          v-for="item in menu"
          :key="item.id"
          :item="item"
        />
      </ul>
    </nav>
  </div>
</template>

<script>
import AppNavItems from '@/components/AppNavItems.vue'
import { getPrimaryMenu } from '@/utils/menu'

export default {
  name: 'AppNav',
  components: {
    AppNavItems
  },
  data () {
    return {
      menu: getPrimaryMenu()
    }
  },
  computed: {
    groups: function () {
      return this.$store.getters['filters/groups']
    },
    groupId: {
      get: function () {
        return this.$store.getters['filters/groupId']
      },
      set (value) {
        this.$store.commit('filters/setGroupId', value)
      }
    },
    countries: function () {
      return this.$store.getters['filters/countries'].map(country => {
        country.label = country.name
        return country
      })
    },
    countryCode: {
      get: function () {
        return this.$store.getters['filters/countryCode']
      },
      set (value) {
        this.$store.commit('filters/setCountryCode', value)
      }
    }
  },
  watch: {
    // groupId: async function () {
    //   try {
    //     this.$store.commit('app/setLoading', true)
    //     await this.$store.dispatch('filters/downloadCategoriesSites')
    //     this.$store.commit('filters/resetSelectedFilters')
    //     this.$store.commit('filters/incrementChangeCounter')
    //   } catch (error) {
    //     this.doFeedback()
    //   } finally {
    //     this.$store.commit('app/setLoading', false)
    //   }
    // },
    countryCode: function () {
      this.$store.commit('filters/resetCategoryIdAndSiteId')
      this.$store.commit('filters/incrementChangeCounter')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
