<template>
    <div>
      <div class="flex items-center space-x-2 mt-3">
        <span class="">{{ $t('app.from') }}</span>
        <span v-if="fromValue.position === 'left'">{{ currency }}</span>
        <span class="price-from px-2 py-1 bg-primary-body font-medium rounded-lg text-center">{{ fromValue.number }}</span>
        <span v-if="fromValue.position === 'right'">{{ currency }}</span>
        <span class="">{{ $t('app.to') }}</span>
        <span v-if="toValue.position === 'left'">{{ currency }}</span>
        <span class="price-to px-2 py-1 bg-primary-body font-medium rounded-lg text-center">{{ toValue.number }}</span>
        <span v-if="toValue.position === 'right'">{{ currency }}</span>
      </div>
      <div class="mt-4 px-2">
        <Slider class="slider-branded" :format="config.format" :min="config.min" :max="config.max" :tooltips="config.tooltips" showTooltip="focus" v-model="localValue" @change="changeValue" />
      </div>
    </div>
</template>

<script>
import Slider from '@vueform/slider/dist/slider.vue2.js'
import { getCurrencyFormat } from '@/i18n/numbers'

export default {
  name: 'PriceSlider',
  components: {
    Slider
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    currency: {
      type: String,
      default: '-'
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      localValue: this.value
    }
  },
  computed: {
    fromValue: function () {
      return this.currencySymbolPosition(this.localValue[0], this.countryCode)
    },
    toValue: function () {
      return this.currencySymbolPosition(this.localValue[1], this.countryCode)
    },
    countryCode: function () {
      return this.$store.getters['filters/countryCode']
    }
  },
  watch: {
    value: function (newValue) {
      this.localValue = newValue
    }
  },
  methods: {
    changeValue: function (value) {
      this.$emit('input', value)
    },
    currencySymbolPosition: function (value, currencyCode = null, locale = null) {
      const currency = getCurrencyFormat(value, currencyCode, locale, 'parts')

      const symbolPosition = currency.findIndex(item => item.type === 'currency')
      const numberPosition = currency.findIndex(item => item.type === 'integer')

      return {
        number: value,
        position: (symbolPosition < numberPosition) ? 'left' : 'right'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slider-branded {
  --slider-handle-width: 20px;
  --slider-handle-height: 20px;
  --slider-handle-shadow: 0.5px 0.5px 2px 1px rgba(8,109,255,.32);
  --slider-handle-shadow-active: 0.5px 0.5px 2px 1px rgba(8,109,255,.42);
  --slider-handle-bg:var(--primary-700);
  --slider-connect-bg: var(--primary-700);
  --slider-tooltip-bg: var(--primary-700);
  --slider-handle-ring-color: var(--primary-800);
}
</style>
