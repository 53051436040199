<template>
  <header class="flex flex-col xl:flex-row items-center flex-shrink-0 bg-primary-body select-none">
    <div
      ref="menu"
      v-outside:click="{ exclude: ['menu'], handler: closeMenu }">
      <div
        class="2xl:min-w-aside 2xl:bg-white flex items-center group py-4 pl-4 dropdown-area relative">
        <div class="w-9 h-9 bg-white shadow rounded-full flex items-center justify-center border border-solid border-gray-100">
          <base-logo />
        </div>
        <div class="whitespace-nowrap relative">
          <section class="flex flex-col ml-2 2xl:hidden">
            <h1 class="font-bold text-sm">{{ section }}</h1>
            <h2 class="text-xs font-medium">by Singular<span class="font-bold">Rank</span></h2>
          </section>
          <section class="hidden 2xl:flex flex-col ml-2">
            <h1 class="font-medium text-sm">Singular<span class="font-bold">Rank</span></h1>
          </section>
          <base-drop-down
            :isOpen="openMenu"
            position="top-left"
            customClass="w-65 top-9 2xl:hidden"
            @toggle="toggleMenu"
          >
            <app-nav />
            <div class="p-2">
              <app-nav-user />
            </div>
          </base-drop-down>
        </div>
        <button
          class="ml-2 h-9 w-9 bg-white rounded-full shadow hover:shadow-md group transition-all duration-150 2xl:hidden" @click="toggleMenu">
          <span
            class="block transform transition-all duration-150 hover:text-primary"
            :class="{'rotate-180 text-primary':openMenu}">
            <base-icon name="chevrondown" />
          </span>
        </button>
      </div>
    </div>
    <general-menu></general-menu>
  </header>
</template>
<script>
import GeneralMenu from '@/components/core/AppGeneralMenuHeader.vue'
import AppNav from '@/components/AppNav.vue'
import AppNavUser from '@/components/AppNavUser.vue'
import { getPrimaryMenu } from '@/utils/menu'

export default {
  name: 'AppHeader',
  components: {
    GeneralMenu,
    AppNav,
    AppNavUser
  },
  data: function () {
    return {
      openMenu: false,
      menu: getPrimaryMenu()
    }
  },
  computed: {
    section: function () {
      const name = this.$router.currentRoute.name
      const item = this.menu.find(item => item.path === name)

      return item ? item.name : ''
    }
  },
  methods: {
    toggleMenu: function () {
      this.openMenu = !this.openMenu
    },
    closeMenu: function () {
      this.openMenu = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .slider-branded {
    --slider-handle-width: 20px;
    --slider-handle-height: 20px;
    --slider-handle-shadow: 0.5px 0.5px 2px 1px rgba(8,109,255,.32);
    --slider-handle-shadow-active: 0.5px 0.5px 2px 1px rgba(8,109,255,.42);
    --slider-handle-bg:var(--primary-700);
    --slider-connect-bg: var(--primary-700);
    --slider-tooltip-bg: var(--primary-700);
    --slider-handle-ring-color: var(--primary-800);
  }
  .vc-container {
    border: none !important;
  }
  .max-height-calendar{
    max-height: 400px;
  }
</style>
