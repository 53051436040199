<template>
  <li class="py-2">
      <span class="flex items-center justify-between">
        <base-input-radio :name="name" :item="item" field-label="name" :value="item.name" @input="changeValue" :checked="checked" />
        <span v-if="item.disabled" class="text-sm text-secondary-500 flex-none">{{ $t('app.notAvailable') }}</span>
      </span>
  </li>
</template>

<script>
export default {
  name: 'where-list-item',
  props: {
    item: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
