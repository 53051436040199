<template>
  <div class="w-11/12 mx-auto xl:w-full xl:mx-0 flex flex-col lg:flex-row items-center xl:justify-between py-2 xl:py-0 2xl:pl-10 xl:pr-4">
    <div class="w-full flex justify-center lg:justify-start xl:justify-center 2xl:justify-start space-x-3">
      <div
        ref="where"
        v-outside:click="{ exclude: ['where'], handler: closeWhere }"
        class="relative dropdown-area"
      >
        <base-header-filter-select
          :data="where"
          :isOpen="openWhere"
          @click="toggleWhere"
        />
        <base-drop-down
          :isOpen="openWhere"
          @toggle="toggleWhere"
          position="left"
          customClass="w-categories"
        >
          <div
            class="flex p-2 font-medium overflow-hidden"
            style="max-height:275px;"
          >
            <div v-if="menuOptions.includes('sites')" class="w-full overflow-y-auto">
              <header class="p-3 pb-1 sticky top-0 bg-white z-10">
                <h3 class="font-bold text-3xl leading-none">{{ $t('app.markets') }}</h3>
                <span class="font-semibold text-secondary-500" v-html="$t('app.whereYouAreWith', { item: category })"></span>
              </header>
              <WhereList v-model="site" name="sites" :data="sites" />
            </div>
            <div v-if="menuOptions.includes('categories')" class="w-full bg-primary-body rounded-tr-lg rounded-br-lg overflow-y-auto">
              <header class="p-3 pb-1 sticky top-0 bg-primary-body z-10">
                <h3 class="font-bold text-3xl leading-none">{{ $t('app.categories') }}</h3>
                <span class="font-semibold text-secondary-500" v-html="$t('app.whereYouAreIn', { item: site })"></span>
              </header>
              <WhereList v-model="category" name="categories" :data="categories" />
            </div>
          </div>
        </base-drop-down>
      </div>
      <div
        ref="how"
        v-outside:click="{ exclude: ['how'], handler: closeHow }"
        class="relative dropdown-area"
        :class="{ 'cursor-not-allowed': !menuOptions.includes('price') }"
      >
        <base-header-filter-select
          :data="howMuch"
          :isOpen="openHow"
          :isDisabled="!menuOptions.includes('price')"
          @click="toggleHow"
        />
        <base-drop-down
          :isOpen="openHow"
          @toggle="toggleHow"
          position="left"
          customClass="w-80"
        >
          <div class="p-4">
            <h3 class="font-bold text-3xl">{{ $t('app.price') }}</h3>
            <price-slider v-model="priceRange" :config="priceSliderConfig" :currency="currency"></price-slider>
            <div class="mt-6 flex items-center justify-end space-x-2">
              <base-button mode="soft" size="xs" @click="resetSlider">{{ $t('app.reset') }}</base-button>
              <base-button size="xs" @click="applyPriceChanges">{{ $t('app.apply') }}</base-button>
            </div>
          </div>
        </base-drop-down>
      </div>
      <div
        ref="when"
        v-outside:click="{ exclude: ['when'], handler: closeWhen }"
        class="relative dropdown-area"
        :class="{ 'cursor-not-allowed': !menuOptions.includes('price') }"
      >
        <base-header-filter-select
          :data="when"
          :isOpen="openWhen"
          :isDisabled="!menuOptions.includes('date')"
          @click="toggleWhen"
        />
        <base-drop-down
          :isOpen="openWhen"
          @toggle="toggleWhen"
          position="center"
          customClass="w-calendar"
        >
          <div class="flex overflow-hidden">
            <div class="w-48 bg-primary-body max-height-calendar overflow-y-auto rounded-tl-lg rounded-bl-lg">
              <ul class="p-1">
                <li v-for="(preset, index) in momentPresets.primary" :key="index" :class="{ disabled: preset.disabled }">
                  <div :class="{ 'pointer-disabled': preset.disabled }" @click="setDates(preset.start, preset.end)" class="flex flex-col text-sm px-3 py-1 hover:bg-white rounded-lg cursor-pointer hover:text-primary group">
                    <span class="font-medium">{{ preset.name }}</span>
                    <span class="text-secondary-500 group-hover:text-secondary"><span>{{ preset.startLabel }}</span> &middot; <span>{{ preset.endLabel }}</span></span>
                  </div>
                </li>
              </ul>
              <ul class="p-1">
                <li v-for="(preset, index) in momentPresets.secondary" :key="index" :class="{ disabled: preset.disabled }" class="compact">
                  <div :class="{ 'pointer-disabled': preset.disabled }" @click="setDates(preset.start, preset.end)" class="flex flex-col text-sm px-3 py-1 hover:bg-white rounded-lg cursor-pointer hover:text-primary group">
                    <span class="font-medium">{{ preset.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="flex-auto">
              <header class="px-4 pt-4">
                <h3 class="font-bold text-3xl">{{ $t('app.dates') }}</h3>
              </header>
              <div class="p-4">
                <DatePicker ref="datepicker" v-model="dateRange" :locale="locale" is-range :columns="$screens({ default: 1, lg: 2 })" trim-weeks :min-date="minDate" :max-date="maxDate" />
              </div>
              <div class="px-4 pb-4 flex items-center justify-end space-x-2">
                <base-button mode="soft" size="xs" @click="resetDates">{{ $t('app.reset') }}</base-button>
                <base-button size="xs" @click="applyDateChanges">{{ $t('app.apply') }}</base-button>
              </div>
            </div>
          </div>
        </base-drop-down>
      </div>
    </div>
    <div class="flex items-center mt-3 lg:mt-0 space-x-3">
      <tab-nav v-if="!isHiddenView" :tabs="tabs" :disabled="isHiddenView" />
      <div
        v-if="menuOptions.includes('order')"
        ref="order"
        v-outside:click="{ exclude: ['order'], handler: closeOrder }"
        class="relative dropdown-area"
        :class="{ 'cursor-not-allowed': !menuOptions.includes('order') }"
      >
        <base-header-filter-select
          :label="$t('app.ordenation')"
          :isOpen="openOrder"
          @click="toggleOrder"
        />
        <base-drop-down
          :isOpen="openOrder"
          position="top-right"
          customClass="w-full lg:w-65"
          @toggle="toggleOrder"
        >
          <div>
            <header class="p-3 pb-2">
              <h3 class="font-bold text-3xl">{{ $t('app.order') }}</h3>
            </header>
            <order-list :data="order" />
          </div>
        </base-drop-down>
      </div>
      <base-button size="xs" @click="openFilterPanel" v-if="menuOptions.includes('filters')" :disabled="!menuOptions.includes('filters')">{{ $t('app.filters') }}</base-button>
      <div
        ref="data"
        v-outside:click="{ exclude: ['data'], handler: closeData }"
        class="relative dropdown-area"
      >
        <base-button size="xs" @click="toggleData" v-if="menuOptions.includes('csv') && csvLinks && csvLinks.length" :disabled="!menuOptions.includes('csv')"><base-icon name="download" size="sm ml-2" /></base-button>
        <base-drop-down
            :isOpen="openData"
            @toggle="toggleData"
            position="top-right"
            customClass="w-80 z-40"
          >
            <div class="p-4">
              <h3 class="font-bold text-3xl">{{ $t('csv.downloadData') }}</h3>
            </div>
            <ul class="dowload-list">
              <li v-for="link in csvLinks" :key="link.ref">
                <a href="#" @click="downloadCsv(link.ref)" class="flex items-center">
                  <span class="w-full">
                    <span class="block leading-tight font-medium">{{ link.title }}</span>
                    <span class="text-sm opacity-80 text-secondary">{{ link.subtitle }}</span>
                  </span>
                  <span class="group-hover:bg-white flex-shrink-0 w-10 h-10 rounded-full bg-primary-50 flex items-center justify-center">
                    <base-icon name="download" size="xs" />
                  </span>
                </a>
              </li>
            </ul>
          </base-drop-down>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import TabNav from '@/components/TabNav'
import OrderList from '@/components/OrderList.vue'
import WhereList from '@/components/WhereList.vue'
import PriceSlider from '@/components/PriceSlider.vue'
import moment from 'moment'
import { getPrimaryMenu } from '@/utils/menu'
import { getCalendarPresets } from '@/utils/calendar'
import { getCurrencyFormat } from '@/i18n/numbers'

export default {
  name: 'AppGeneralMenuHeader',
  components: {
    WhereList,
    DatePicker,
    OrderList,
    PriceSlider,
    TabNav
  },
  data: function () {
    return {
      tabs: ['table', 'card'],
      priceRange: [0, 1],
      priceSliderConfig: {
        min: 0,
        max: 1,
        tooltips: false,
        showTooltip: 'drag'
      },
      dateRange: {
        start: new Date(),
        end: new Date()
      },
      menu: getPrimaryMenu(),
      calendarPresets: [],
      openMenu: false,
      openWhere: false,
      openHow: false,
      openWhen: false,
      openOrder: false,
      openData: false
    }
  },
  computed: {
    order: function () {
      return this.$store.getters['filters/order']
    },
    csvLinks: function () {
      return this.$store.getters['app/csvOptions'].links
    },
    locale: function () {
      return this.$store.getters['auth/locale']
    },
    menuOptions: function () {
      if (this.$route.meta.menu && Array.isArray(this.$route.meta.menu)) {
        return this.$route.meta.menu
      }

      return []
    },
    categories: function () {
      const locale = this.locale
      return this.$store.getters['filters/categories'](locale)
    },
    sites: function () {
      return this.$store.getters['filters/sites']
    },
    groupId: function () {
      return this.$store.getters['filters/groupId']
    },
    category: {
      get: function () {
        const locale = this.locale
        return this.$store.getters['filters/category'](locale)
      },
      set (category) {
        const locale = this.locale
        this.$store.commit('filters/setCategory', { category, locale })
        this.$store.commit('filters/incrementChangeCounter')
      }
    },
    site: {
      get: function () {
        return this.$store.getters['filters/site']
      },
      set (value) {
        this.$store.commit('filters/setSite', value)
        this.$store.commit('filters/incrementChangeCounter')
      }
    },
    where: function () {
      const result = []
      if (this.menuOptions.includes('sites')) {
        result.push(this.site)
      }
      if (this.menuOptions.includes('categories')) {
        result.push(this.category)
      }
      return result
    },
    currency: function () {
      return this.$store.getters['filters/currency']
    },
    countryCode: function () {
      return this.$store.getters['filters/countryCode']
    },
    minPrice: function () {
      return this.$store.getters['filters/minPrice']
    },
    maxPrice: function () {
      return this.$store.getters['filters/maxPrice']
    },
    generalMaxPrice: function () {
      return this.$store.getters['filters/general']?.maxPrice
    },
    howMuch: function () {
      return [
        getCurrencyFormat(this.minPrice, this.countryCode),
        getCurrencyFormat(this.maxPrice, this.countryCode)
      ]
    },
    startDate: function () {
      return this.$store.getters['filters/startDate']
    },
    endDate: function () {
      return this.$store.getters['filters/endDate']
    },
    minDate: function () {
      return this.$store.getters['filters/general']?.dateRange.start
    },
    maxDate: function () {
      return this.$store.getters['filters/general']?.dateRange.end
    },
    when: function () {
      const start = moment(this.startDate, 'YYYY-MM-DD').format('D MMM, YY')
      const end = moment(this.endDate, 'YYYY-MM-DD').format('D MMM, YY')
      return [start, end]
    },
    momentPresets: function () {
      const presets = {
        primary: [],
        secondary: []
      }

      for (const preset of this.calendarPresets) {
        const p = {
          name: preset.name,
          start: preset.start,
          end: preset.end,
          startLabel: moment(preset.start).format('D MMM, YY'),
          endLabel: moment(preset.end).format('D MMM, YY'),
          disabled: !!preset.disabled,
          format: preset.format
        }

        if (preset.format === 'compact') {
          presets.secondary.push(p)
        } else {
          presets.primary.push(p)
        }
      }

      return presets
    },
    isHiddenView: function () {
      const routeName = this.$route.name
      const hiddenView = this.$store.getters['filters/hiddenView']

      if (routeName === 'dashboard' && hiddenView) {
        return true
      }

      return !this.menuOptions.includes('views')
    }
  },
  created: function () {
    this.initializePrices()
    this.initializeDate()
  },
  methods: {
    initializePrices: function () {
      this.priceRange = [this.minPrice, this.maxPrice]
      this.priceSliderConfig.min = 0
      this.priceSliderConfig.max = this.generalMaxPrice
    },
    initializeDate: function () {
      this.dateRange.start = new Date(this.startDate)
      this.dateRange.end = new Date(this.endDate)
      this.calendarPresets = getCalendarPresets(this.minDate, this.maxDate)
    },
    toggleMenu: function () {
      this.openMenu = !this.openMenu
    },
    closeMenu: function () {
      this.openMenu = false
    },
    toggleWhere: function () {
      this.openWhere = !this.openWhere
    },
    closeWhere: function () {
      this.openWhere = false
    },
    toggleHow: function () {
      this.openHow = !this.openHow
    },
    closeHow: function () {
      this.openHow = false
    },
    toggleWhen: function () {
      this.openWhen = !this.openWhen
    },
    closeWhen: function () {
      this.openWhen = false
    },
    toggleOrder: function () {
      this.openOrder = !this.openOrder
    },
    closeOrder: function () {
      this.openOrder = false
    },
    toggleData: function () {
      this.openData = !this.openData
    },
    closeData: function () {
      this.openData = false
    },
    resetSlider: function () {
      this.priceRange = [this.priceSliderConfig.min, this.priceSliderConfig.max]
    },
    applyPriceChanges: function () {
      const min = this.priceRange[0]
      const max = this.priceRange[1]
      this.$store.commit('filters/setMinPrice', min)
      this.$store.commit('filters/setMaxPrice', max)
      this.$store.commit('filters/incrementChangeCounter')
      this.toggleHow()
    },
    resetDates: function () {
      this.setDates(this.startDate, this.endDate)
    },
    setDates: function (start, end) {
      this.dateRange.start = new Date(start)
      this.dateRange.end = new Date(end)

      this.$refs.datepicker.updateValue(this.dateRange)
      this.$refs.datepicker.focusDate(this.dateRange.end)
    },
    applyDateChanges: function () {
      const start = moment(this.dateRange.start).format('YYYY-MM-DD')
      const end = moment(this.dateRange.end).format('YYYY-MM-DD')
      this.$store.commit('filters/setStartDate', start)
      this.$store.commit('filters/setEndDate', end)
      this.$store.commit('filters/incrementChangeCounter')
      this.toggleWhen()
    },
    openFilterPanel: function () {
      this.$store.commit('app/setIsOpenFilterPanel', true)
    },
    downloadCsv: function (ref) {
      this.$store.commit('app/setCsvRef', ref)
      this.$store.commit('app/incrementCsvCounter')
    }
  },
  watch: {
    generalMaxPrice: function (value) {
      this.priceSliderConfig.max = value
      this.priceRange = [this.minPrice, this.maxPrice]
    },
    site: function () {
      this.initializeDate()
    },
    category: function () {
      this.initializeDate()
    }
  }
}
</script>
<style lang="scss" scoped>
  .vc-container {
    border: none !important;
  }
  .max-height-calendar{
    max-height: 400px;
  }
  .compact {
    display: inline-block;
  }
  .disabled {
    cursor: not-allowed;
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, .4);
    }
  }
  .pointer-disabled {
    pointer-events: none;
  }

  .dowload-list {
    li {
      @apply p-4 border-b border-solid border-secondary-50 group;
      &:hover {
        @apply bg-primary-50;
      }
      &:last-child {
        @apply border-none;
      }
    }
  }
</style>
